import { DqTable } from "@decentriq/components";
import { WorkerTypeShortName } from "@decentriq/graphql/dist/types";
import {
  Grid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  type TabsProps,
  Typography,
} from "@mui/joy";
import { parse } from "papaparse";
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import useDependsOnPreviewNode from "features/computeNode/components/ComputeNodeToolbar/useDependsOnPreviewNode";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";
import {
  type ComputationResultMeta,
  decodeComputationResult,
  getScriptingNodeFileResult,
} from "utils/apicore";
import MatchingNodeResult from "./MatchingNodeResult/MatchingNodeResult";

interface ComputeNodeDataResultTableProps {
  computeNodeId: string;
  bytes: Uint8Array;
  isLoading: boolean;
  hasRunComputation: boolean;
  computationTypename: WorkerTypeShortName;
}

const MAX_COLUMNS_TO_DISPLAY = 100;

const ComputeNodeDataResultTable = memo<ComputeNodeDataResultTableProps>(
  ({
    computationTypename,
    hasRunComputation,
    isLoading,
    bytes,
    computeNodeId,
  }) => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const [parsed, setParsed] = useState<ComputationResultMeta | undefined>();
    const [error, setError] = useState<string>("");
    const result = useMemo(() => (parsed && parsed.data) || [], [parsed]);
    const [currentCsvResultTab, setCurrentCsvResultTab] = useState(
      result[0]?.filename || "__default__"
    );
    const imagesResult = (parsed && parsed?.images) || [];
    const handleCsvResultTabChange = useCallback<
      Exclude<TabsProps["onChange"], undefined>
    >((_, newValue) => {
      setCurrentCsvResultTab(newValue?.toString() || "__default__");
    }, []);
    const { dependsOnPreviewNode } = useDependsOnPreviewNode({ computeNodeId });
    // Set default csv result tab
    useEffect(() => {
      handleCsvResultTabChange({} as React.SyntheticEvent, result[0]?.filename);
    }, [handleCsvResultTabChange, result]);
    useEffect(() => {
      if (bytes) {
        const handlePreviewError = (error: Error) => {
          // Check the case when the ZIP file couldn't be constructed as we dind't
          // fetch all the chunks of the result (during the preview we only fetch
          // a single chunk).
          if (
            /Corrupted zip: can't find end of central directory/gi.test(
              error.message
            )
          ) {
            setError(
              "This computation result is too large to be previewed. Alternatively, please use the download option."
            );
          } else {
            enqueueSnackbar(
              ...mapErrorToGeneralSnackbar(
                error,
                "This computation result cannot be previewed."
              )
            );
          }
        };
        if (
          computationTypename === WorkerTypeShortName.Sql ||
          computationTypename === WorkerTypeShortName.Sqlite ||
          computationTypename === WorkerTypeShortName.Synthetic ||
          computationTypename === WorkerTypeShortName.Post
        ) {
          decodeComputationResult(bytes)
            .then((result) => {
              setParsed({
                data: result[0]
                  ? [
                      {
                        filename: "",
                        result: parse(result[0], { skipEmptyLines: true })
                          .data as string[][],
                      },
                    ]
                  : [],
                schema: result[1],
              });
            })
            .catch(handlePreviewError);
        } else if (
          computationTypename === WorkerTypeShortName.Python ||
          computationTypename === WorkerTypeShortName.R
        ) {
          getScriptingNodeFileResult(bytes)
            .then(setParsed)
            .catch(handlePreviewError);
        }
      }
    }, [bytes, computationTypename, enqueueSnackbar]);
    const header =
      parsed && (parsed.schema?.namedColumns?.map((n) => n.name || "") || []);
    const headerLength = (header || []).length;
    const resultsData = useMemo<
      {
        data: string[][];
        filename: string;
        rowLength: number;
      }[]
    >(() => {
      if (result && result.length) {
        return result.map(({ result: resultData = [], filename }) => ({
          data: resultData.filter((r) => r.some(Boolean)),
          filename,
          rowLength: headerLength || resultData[0]?.length,
        }));
      }
      return [];
    }, [result, headerLength]);
    if (
      hasRunComputation &&
      !isLoading &&
      computationTypename === WorkerTypeShortName.Match
    ) {
      return <MatchingNodeResult bytes={bytes} id={computeNodeId} />;
    }
    return (
      <Stack
        sx={{
          userSelect: dependsOnPreviewNode ? "none" : "auto",
        }}
      >
        {hasRunComputation && !isLoading ? (
          <Fragment>
            {parsed?.logs && (
              <div
                style={{
                  backgroundColor: "var(--joy-palette-neutral-500)",
                  padding: ".25rem .5rem",
                  width: "100%",
                }}
              >
                <Typography level="body-sm">
                  <strong>Output:</strong>
                </Typography>
                <pre
                  style={{
                    margin: "0 0 1rem",
                    whiteSpace: "pre-wrap",
                    width: "100%",
                  }}
                >
                  {parsed?.logs}
                </pre>
              </div>
            )}
            {/* Render images */}
            {imagesResult?.length >= 0 && (
              <Grid columnSpacing={2} container={true} mb={2}>
                {imagesResult.map((base64Image, index) => (
                  <Grid
                    key={index}
                    xs={imagesResult.length <= 3 ? 12 / imagesResult.length : 4}
                  >
                    <img
                      alt={`Computation result ${index}`}
                      src={`data:image;base64,${base64Image}`}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            {/* Render computation result as tabs */}
            {result && result.length ? (
              <Tabs
                onChange={handleCsvResultTabChange}
                value={currentCsvResultTab}
              >
                {resultsData.length > 1 && (
                  <TabList>
                    {resultsData.map(({ filename }) => (
                      <Tab key={filename} value={filename || "__default__"}>
                        {filename}
                      </Tab>
                    ))}
                  </TabList>
                )}
                {resultsData.map(
                  ({ data, filename, rowLength: columnsLength }) => {
                    const effectiveColumnsLength = Math.min(
                      columnsLength,
                      MAX_COLUMNS_TO_DISPLAY
                    );

                    const columns = Array.from({
                      length: effectiveColumnsLength,
                    }).map((_, index) => ({
                      accessorFn: (row: string[]) => row[index],
                      header: (header || [])[index] || "—",
                      id: index.toString(),
                    }));
                    return (
                      <TabPanel
                        key={filename}
                        value={filename || "__default__"}
                      >
                        <DqTable columns={columns} data={data} />
                        {effectiveColumnsLength < columnsLength && (
                          <Typography level="body-sm">
                            Showing {effectiveColumnsLength} of {columnsLength}{" "}
                            columns
                          </Typography>
                        )}
                      </TabPanel>
                    );
                  }
                )}
              </Tabs>
            ) : computationTypename === WorkerTypeShortName.S3 ? (
              "Upload completed successfully."
            ) : [
                WorkerTypeShortName.Match,
                WorkerTypeShortName.Python,
                WorkerTypeShortName.R,
              ].includes(computationTypename) ? (
              ""
            ) : (
              <span style={{ marginLeft: "8px" }}>
                {error || "Your computation returned an empty result."}
              </span>
            )}
          </Fragment>
        ) : null}
      </Stack>
    );
  }
);
ComputeNodeDataResultTable.displayName = "ComputeNodeDataResultTable";

export default ComputeNodeDataResultTable;
