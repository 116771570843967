import { DqLoader } from "@decentriq/components";
import { type DataRoomsListQuery } from "@decentriq/graphql/dist/types";
import { faArrowRight, faCubes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Stack } from "@mui/joy";
import { memo } from "react";
import { NavLink } from "react-router-dom";
import { EmptyData } from "components";
import { RecentDataRoom } from "components/entities/dataRooms/RecentDataRoomsBlock/components";

interface DataRoomsBlockCardContentProps {
  dataRooms: DataRoomsListQuery["dataRooms"]["nodes"];
  isDataRoomsLoading: boolean;
}

// Number of datarooms to be shown in the block
const DATA_ROOMS_THRESHOLD = 5;

const DataRoomsBlockCardContent = memo<DataRoomsBlockCardContentProps>(
  ({ dataRooms, isDataRoomsLoading }) => {
    if (isDataRoomsLoading) {
      return <DqLoader />;
    }

    if (dataRooms.length === 0) {
      return <EmptyData icon={faCubes} title="No data clean rooms" />;
    }

    const visibleDataRooms = dataRooms.slice(0, DATA_ROOMS_THRESHOLD);

    return (
      <Stack gap={1} width="100%">
        <Stack width="100%">
          {visibleDataRooms.map((dataRoom) => (
            <RecentDataRoom
              dataRoom={dataRoom}
              key={dataRoom.id}
              variant="outlined"
            />
          ))}
        </Stack>
        {dataRooms.length > DATA_ROOMS_THRESHOLD && (
          <Button
            color="primary"
            component={NavLink}
            endDecorator={
              <FontAwesomeIcon
                fixedWidth={true}
                // TODO: Fix in the Joy theme so fontSize of startDecorator and endDecorator icons are same
                fontSize={16}
                icon={faArrowRight}
              />
            }
            fullWidth={true}
            sx={{ mt: "auto" }}
            to="/datarooms"
            variant="soft"
          >
            View all
          </Button>
        )}
      </Stack>
    );
  }
);

DataRoomsBlockCardContent.displayName = "DataRoomsBlockCardContent";

export default DataRoomsBlockCardContent;
