import { Chip } from "@mui/joy";
import { memo } from "react";

type AffinityRatioChipProps = {
  value: number;
};

const AffinityRatioChip = memo<AffinityRatioChipProps>(({ value }) => {
  return value >= 0.5 ? (
    <Chip
      sx={({ palette }) => ({
        "& .MuiChip-label": {
          color: palette.primary.plainColor,
          padding: "0 8px",
        },
        backgroundColor:
          palette.affinityRatio[
            (value > 2
              ? "2.0"
              : value < 0.5
                ? "0.5"
                : value.toFixed(1)) as keyof typeof palette.affinityRatio
          ],
        fontSize: "14px",
      })}
    >{`${value.toFixed(1)}x`}</Chip>
  ) : (
    <span style={{ marginLeft: "4px" }}>{`${value.toFixed(1)}x`}</span>
  );
});
AffinityRatioChip.displayName = "AffinityRatioChip";

export default AffinityRatioChip;
