// TODO: Fix joy migration
import { faChartSimple } from "@fortawesome/pro-light-svg-icons";
import { Box, useTheme } from "@mui/joy";
import { type PointTooltipProps, ResponsiveLine } from "@nivo/line";
import { memo, useMemo } from "react";
import { EmptyData } from "components";
import {
  SuppressedSegmentsInformation,
  useOverlapInsightsAggregation,
} from "features/mediaDataRoom";
import {
  SHARE_IN_ADDRESSABLE_AUDIENCE_CHART_KEY,
  SHARE_IN_OVERLAP_CHART_KEY,
} from "../../constants";

const LineTooltip: React.FC<PointTooltipProps> = ({
  point: { serieId, data } = {},
}) => (
  <Box
    sx={{
      // NOTE: Exact RadarChart's tooltip styles
      background: "white",
      borderRadius: "2px",
      boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
      color: "inherit",
      fontSize: "inherit",
      padding: "5px 9px",
    }}
  >
    <Box>
      <strong>Aggregation:</strong> {data?.x}
    </Box>
    <Box>
      <strong>{serieId}:</strong> {((data?.y || 0) * 100).toFixed(1)}%
    </Box>
  </Box>
);

const LineChart: React.FC = () => {
  const {
    aggregationData,
    hasHiddenValues,
    hasSuppressedValues,
    hiddenValues,
    suppressedValues,
    possibleValues,
  } = useOverlapInsightsAggregation();

  const { palette } = useTheme();

  const lineChartData = useMemo(
    () => [
      {
        data: aggregationData.map(({ aggregationName, shareInOverlap }) => ({
          x: aggregationName,
          y: shareInOverlap,
        })),
        id: SHARE_IN_OVERLAP_CHART_KEY,
      },
      {
        data: aggregationData.map(
          ({ aggregationName, shareInMatchableAudience }) => ({
            x: aggregationName,
            y: shareInMatchableAudience,
          })
        ),
        id: SHARE_IN_ADDRESSABLE_AUDIENCE_CHART_KEY,
      },
    ],
    [aggregationData]
  );

  return (
    <>
      {(hasSuppressedValues || hasHiddenValues) && (
        <SuppressedSegmentsInformation
          hiddenValues={hiddenValues}
          possibleValues={possibleValues}
          suppressedValues={suppressedValues}
        />
      )}
      {lineChartData.length ? (
        <ResponsiveLine
          axisLeft={{
            format: (value) => `${((value || 0) * 100).toFixed(1)}%`,
          }}
          {...(aggregationData.length > 6 ? { axisBottom: null } : {})}
          animate={false}
          colors={[palette.chart["900"], palette.chart["500"]]}
          data={lineChartData}
          enableArea={true}
          enableCrosshair={true}
          isInteractive={true}
          legends={[
            {
              anchor: "top-left",
              direction: "row",
              itemDirection: "left-to-right",
              itemHeight: 20,
              itemOpacity: 0.75,
              itemWidth: 0,
              itemsSpacing: 200,
              justify: false,
              symbolSize: 12,
              translateX: -32,
              translateY: -32,
            },
          ]}
          margin={{
            bottom: aggregationData.length > 6 ? 8 : 32,
            left: 48,
            right: aggregationData.length > 6 ? 0 : 32,
            top: 32,
          }}
          tooltip={LineTooltip}
          useMesh={true}
        />
      ) : (
        <EmptyData icon={faChartSimple} title="No chart data available" />
      )}
    </>
  );
};

LineChart.displayName = "LineChart";

export default memo(LineChart);
